html {
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
    padding: 0;
}


*:focus:not(.focus-visible),
*:focus:not(:focus-visible) {
    outline: none;
}

*:focus-visible, .focus-visible {
    outline: 2px solid #6ba6f6;
}